.chat {
  /* box-shadow: 1px 1px 10px rgba(29, 29, 29, 0.102); */
  margin: 0 auto;
  padding: 1em;
  margin: 2em;
  width: calc(100% - 6em);
  height: 30vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  transition: 0.15s ease-out;
}

.chat:hover {
  padding: 4em 1em;
  margin: 2em;
  box-shadow: 1px 1px 10px rgba(113, 113, 113, 0.234);
  width: calc(90% - 2em);
  margin: 0em auto;
  border-radius: 1em;
}

.chatActive {
  padding: 0;
  width: calc(100% - 2em);
  height: 90vh;
}
.chatActive:hover {
  width: calc(100% - 2em);

  /* box-shadow: inset 0 0 10px #d7d7d7; */
  background-color: #ffffff;
  border-radius: 1em;
}

.messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 25%;
  background-color: #eee;
  position: relative;
}

.yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  margin-left: 25%;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  position: relative;
}

.mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}

.mySwiperMMS {
  margin-right: 2.5em;
  padding-top: 1.2em;
  width: 50%;
}
.yourSwiperClass {
  box-shadow: none;
}

.mySwiperMMS img {
  border-radius: 20px;
}

.swiper-3d .swiper-slide-shadow {
  background: none;
}
