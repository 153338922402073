/* suisse */

@font-face {
  font-family: "SuisseIntl-Bold";
  src: url("../../public/assets/fonts/suisse/SuisseIntl-Bold.ttf");
}

@font-face {
  font-family: "SuisseIntl-Book";
  src: url("../../public/assets/fonts/suisse/SuisseIntl-Book.ttf");
}

@font-face {
  font-family: "SuisseIntl-Regular";
  src: url("../../public/assets/fonts/suisse/SuisseIntl-Regular.ttf");
}

@font-face {
  font-family: "SuisseIntl-SemiBold";
  src: url("../../public/assets/fonts/suisse/SuisseIntl-SemiBold.ttf");
}

/* Clock fonts */

@font-face {
  font-family: "BodoniModa";
  src: url("../../public/assets/fonts/clockFonts/BodoniModa-VariableFont_opsz\,wght.ttf");
}

@font-face {
  font-family: "CoralBlushSerif";
  src: url("../../public/assets/fonts/clockFonts/CoralBlushSerif.otf");
}

@font-face {
  font-family: "DidotLH";
  src: url("../../public/assets/fonts/clockFonts/DidotLH\ Bold.ttf");
}

@font-face {
  font-family: "NeueHaasDisplayRoman";
  src: url("../../public/assets/fonts/clockFonts/NeueHaasDisplayRoman\ copy.ttf");
}

@font-face {
  font-family: "PoiretOne";
  src: url("../../public/assets/fonts/clockFonts/PoiretOne-Regular.ttf");
}

@font-face {
  font-family: "TINY5x3";
  src: url("../../public/assets/fonts/clockFonts/TINY5x3-60.otf");
}

@font-face {
  font-family: "IBMPlexMono";
  src: url("../../public/assets/fonts/IBMPlexMono-Regular.ttf");
}

@font-face {
  font-family: "SuisseIntlMono";
  src: url("../../public/assets/fonts/suisse/SuisseIntlMono-Regular.otf");
}

@font-face {
  font-family: "SuisseIntlMono-Bold";
  src: url("../../public/assets/fonts/suisse/SuisseIntlMono-Bold.otf");
}

@font-face {
  font-family: "SFMonoRegular";
  src: url("../../public/assets/fonts/suisse/SFMonoRegular.otf");
}

@font-face {
  font-family: "Vonique";
  src: url("../../public/assets/fonts/clockFonts/Vonique\ 64\ Bold.ttf");
}
