.mySwiper {
  width: 100%;
  object-fit: contain;
}

.swiper-pagination-bullet {
  font-family: "SuisseIntlMono-Bold";
  padding: 0.2em 0.52em;
  margin-right: 0.1em;
  border-radius: 1em;
  line-height: 24px;
  font-size: 12px;
}

.swiper-pagination-bullet-active {
  border: 1.5px solid rgb(0, 0, 0);
}

.container {
  min-height: 100%;
  display: flex;
  font-size: 1em;
  padding: 0.5em;
}

.container a {
  color: var(--secondary-color);
}

.container p {
  margin-top: 0;
}

.container section:nth-child(1) {
  width: 25%;
  margin-right: 0.5em;
}

.container section:nth-child(1) p {
  position: sticky;
  top: 10px;
}

.container section:nth-child(2) {
  width: 75%;
}

.container section:nth-child(2) img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.container .post {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  padding: 0;
  margin-bottom: 100px;
  position: relative;
  top: 10px;
}

.container .post p {
  max-width: 800px;
  border-radius: 1em;
  padding: 1em 1em 0 1em;
  height: 100%;
}

.container .post a {
  padding: 0 1em;
  width: 100%;
  margin-bottom: 2em;
}

.fixedP {
  max-width: 600px;
  padding: 1em 0 1em 0;
}

@media (max-width: 1200px) {
  .container {
    flex-direction: column;
  }

  .container section:nth-child(1) {
    width: 100%;
    padding-bottom: 4em;
  }

  .container a {
    margin-bottom: 4em;
    width: 30%;
  }

  .container section:nth-child(2) p {
    width: 70%;
  }

  .container section:nth-child(2) {
    width: 100%;
  }

  .fixedP {
    padding-left: 1em;
  }

  hr {
    display: none;
  }
}

@media (max-width: 800px) {
  #info,
  .container section:nth-child(1) {
    margin: 2em 0;
  }

  .mySwiper img {
    max-height: 700px;
  }

  .container section:nth-child(1) {
    display: none;
  }

  .container .post {
    border-top: 0.5px solid #e4e4e4;
    padding-bottom: 12em;
    display: flex;
    flex-direction: column;
  }

  .container .post p {
    padding-top: 6em;
    font-size: 1.125em;
    max-width: 90vw;
    padding-bottom: 0;
  }

  hr {
    display: none;
  }

  .container {
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .container .post {
    position: sticky;
    top: -10px;
    background-color: #ffffff;
    width: 100%;
  }

  .container section:nth-child(2) p {
    width: 100%;
  }
}
