.error .cont {
  padding: 0;
}

.sygnal {
  background-color: rgb(255, 255, 255);

  width: 100%;
  height: 100vh;
}

.cont {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.sygnal div {
  width: 100%;
  height: 20em;
}

.sygnal h1 {
  margin: 0 auto;
  color: #000000;
  background-color: transparent !important;
}

.cont div:nth-child(1) {
  background-color: rgb(240, 240, 240);
}

.cont div:nth-child(2) {
  background-color: rgb(236, 240, 16);
}

.cont div:nth-child(3) {
  background-color: rgb(16, 240, 218);
}

.cont div:nth-child(4) {
  background-color: rgb(20, 240, 16);
}

.cont div:nth-child(5) {
  background-color: rgb(240, 16, 218);
}

.cont div:nth-child(6) {
  background-color: rgb(240, 16, 16);
}

.cont div:nth-child(7) {
  background-color: rgb(16, 65, 240);
}

/*  */

.cont:nth-child(2) div {
  height: 1em;
}

.cont:nth-child(2) div:nth-child(1) {
  background-color: rgb(19, 19, 19);
}

.cont:nth-child(2) div:nth-child(2) {
  background-color: rgb(55, 55, 55);
}

.cont:nth-child(2) div:nth-child(3) {
  background-color: rgb(100, 100, 100);
}

.cont:nth-child(2) div:nth-child(4) {
  background-color: rgb(134, 134, 134);
}

.cont:nth-child(2) div:nth-child(5) {
  background-color: rgb(164, 164, 164);
}

.cont:nth-child(2) div:nth-child(6) {
  background-color: rgb(201, 201, 201);
}

.cont:nth-child(2) div:nth-child(7) {
  background-color: rgb(240, 240, 240);
}

.gridContainer {
  position: relative;
}

.gridContainer div {
  width: 100%;
  height: 100vh;
}

.grid {
  background-image: linear-gradient(#ffffff 1px, transparent 1px),
    linear-gradient(to right, #ffffff 1px, #000099 1px);
  background-size: 10vw 10vw;
}

.error h1 {
  position: absolute;
  background-color: #ffffff;
  text-align: center;
  padding: 2em;
}
