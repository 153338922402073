html,
body {
  box-sizing: border-box;
  overflow-x: hidden;
}

:root {
  /* #d8dcdc */
  --primary-color: rgb(255, 255, 255);
  --secondary-color: #000000;
  scroll-behavior: smooth;
}

*::selection {
  background-color: unset;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "SuisseIntl-Regular", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
  background: var(--primary-color);
  color: var(--secondary-color);
  transition: 0.4s ease-in-out;
}

nav {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: 1.5em;
  z-index: 1000;
  background-color: #fafafaa2;
  box-shadow: 0px 3px 10px rgba(140, 140, 140, 0.25);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  border-radius: 2em;
}

nav > * {
  color: #454545;
  /* background-color: #ffffff76; */

  margin: 1em;
  border-radius: 4em;
  transition: 0.1s ease-out;
}

nav a:hover {
  color: #000000;
  /* background-color: #ffffff93; */
  text-decoration: none;
}

nav span {
  display: inline-block;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  opacity: 0.5;
  text-decoration: none !important;
}

.hero {
  margin: 6em 0;
  height: calc(100dvh);
  width: 100%;
}

iframe {
  width: 360px;
  height: 340px;
}

.window {
  max-width: 420px;
  padding: 0.1em;
  position: absolute;
  background: #f0f0f0;
  color: #000000;
  /* border-radius: 16px; */
  box-shadow: 1px 1px 10px rgba(67, 67, 67, 0.37);
}

.window div:first-child span:nth-child(2):hover {
  color: #a4a4a4 !important;
}

.window a {
  width: 100%;
  margin: 0;
}

.window h3 {
  margin: 8px 0 8px 8px;
}

.window h6 {
  margin: 8px 0 8px 8px;
}

.window a:hover {
  text-decoration: underline;
}

img {
  width: 100%;
  pointer-events: none;
}

.clock {
  padding: 1em;
  width: 190px;
}

.digital {
  padding: 0 0.4em;
  font-size: 3em;
  font-weight: bold;
}

/* clock border */
.react-clock__face {
  border: 0 !important;
}

textarea {
  padding: 1em 0.5em;
  border: none;
  width: 300px;
  font-size: 1.2em;
  height: 120px;
  resize: none;
}

textarea:focus {
  outline: none;
}

::-webkit-scrollbar {
  display: none;
}

.buttonCam {
  display: flex;
}

.buttonCam button {
  border: none;
  font-size: 1.5em;
  padding: 0 0.4em;
  border-radius: 3em;
}

.cam video {
  width: 300px;
  margin: 0;
}

/* .dot {
  margin: 0 0.5em;
  height: 10px;
  width: 10px;
  background-color: lime;
  border-radius: 50%;
} */

#defaultCanvas1 {
  display: none;
}

video {
  width: 100%;
}

hr {
  border: 0;
  height: 1px;
  background: var(--secondary-color);
}

form input[type="text"],
form input[type="text"]::selection {
  border-color: transparent;
  outline: none;
}
form input[type="text"],
form hr {
  display: block;
  float: left;
  width: 50%;
}

#badvisor {
  border-radius: 1em;
  margin: 1em 16px 3em;
  width: calc(100vw - 36px);

  border: none;
  outline: none;
  border: 2px solid rgb(220, 220, 220);
}

.marquee {
  justify-content: center;
  gap: 8em;
}

.marquee img {
  filter: saturate(0);
  padding: 4em;
  object-fit: contain;
  max-width: 10em;
  max-height: 4em;
}

.marquee span {
  margin: 2em 3em;
}

/* mobile             mobile              mobile            mobile            mobile  */
@media only screen and (max-width: 850px) {
  body {
    max-width: 100vw;
  }

  .window {
    max-width: 350px;
  }

  .hero iframe {
    max-width: 350px;
  }

  .marquee {
    gap: 4em;
  }

  #badvisor {
    border-radius: 1em;
    margin: 1em 2em 4em;
    width: calc(100vw - 4em);
  }
}

.toggle {
  transition: transform 0.2s ease;
  transform: rotate(0deg);
}

.toggle:hover {
  transform: rotate(180deg);
}
